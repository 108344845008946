import React from "react";
import classNames from "classnames";
import styles from "./styles.module.scss";

const LilliButton = ({
  variant = "primary",
  children,
  onClick,
  disabled,
  leftIcon,
  rightIcon,
  type = "button",
}) => {
  return (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled}
      className={classNames(styles.button, {
        [styles.primary]: variant === "primary",
        [styles.secondary]: variant === "secondary",
        [styles.danger]: variant === "danger",
        [styles.warning]: variant === "warning",
      })}
    >
      {leftIcon ? <div className={styles.left_icon}>{leftIcon}</div> : null}
      <div>{children}</div>
      {rightIcon ? <div className={styles.right_icon}>{rightIcon}</div> : null}
    </button>
  );
};

export default LilliButton;
