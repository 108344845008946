import {
  postDecommissionHub,
  putHubInactiveStatus,
} from "@intelligentlilli/api-layer";
import { useCallback, useState } from "react";
import { useSelector } from "react-redux";

export const useDeactivateServiceUser = ({
  serviceUserId,
  reason,
  option,
  reasonText,
}) => {
  // redux state
  const server = useSelector((state) => state.session.server);

  // local state
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const deactivateServiceUser = useCallback(async () => {
    setLoading(true);
    setError(false);
    setSuccess(false);

    try {
      const decommissionResponse = await postDecommissionHub(
        server,
        serviceUserId,
        "web",
        reason,
        option,
        reasonText
      );
      if (decommissionResponse.ok) {
        const inactiveResponse = await putHubInactiveStatus(
          server,
          serviceUserId,
          "web"
        );

        if (inactiveResponse.ok) {
          setSuccess(true);
        } else {
          setError(true);
        }
      } else {
        setError(true);
      }
    } catch {
      setError(true);
    } finally {
      setLoading(false);
    }
  }, [option, reason, reasonText, server, serviceUserId]);

  return {
    loading,
    error,
    deactivateServiceUser,
    success,
  };
};
