export const fallbackReasonValues = {
  passedAway: "The user has passed away.",
  hospitalised: "The user is currently hospitalised.",
  other: "Other",
  leavingHome: "The user is leaving their home for other reasons.",
  kitReallocated: "The Lilli kit is being reallocated to another user.",
  noLongerReceivingCare: "The user is no longer receiving care from our team.",
  stepUpCare:
    "The user is transitioning to step-up care (e.g. a care home or respite/home care).",
  carePackageChanged: "The user's care package has been changed.",
};
